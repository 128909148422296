<script setup></script>
<template>
  <footer>
    <img src="@/assets/index/footer_bg.png" class="bg" alt="footer_bg">
  </footer>
</template>
<style scoped lang="scss">
footer {
  width: 100vw;
  max-width: 1920px;
  position: relative;
  height: auto;
  background-color: #2B3C6E;
  margin: 0 auto;
  // background-image: url(@/assets/index/footer_bg.png);
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  // background-position: center center;
  .bg {
    width: 100%;
    height: 100%;
  }
}
</style>
