<script setup>
import { useRouter, useRoute } from "vue-router";
import { ref, watchEffect } from "vue";
import { logout } from "@/api/login.js";
import { removeToken } from "@/utils/auth";
import Cookies from "js-cookie";
import { ElMessage, ElMessageBox } from "element-plus";

const router = useRouter();
const route = useRoute();
const userInfo = ref();
// 退出登录
const logOut = () => {
  ElMessageBox.confirm("请问确定要退出吗?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  }).then(() => {
    logout()
      .then((res) => {
        if (res.code === 200) {
          router.push({ path: "/login" });
          removeToken("token");
          removeToken("user");
          removeToken("roles");
          removeToken("Admin-Token_institution");
        }
      })
      .catch((e) => {
        ElMessage({
          type: "error",
          message: e.message,
        });
      });
  });
};

const tabList = ref([
  { id: 1, name: "首页", path: "/", isSelect: false },
  { id: 2, name: "3551人才计划", path: "/", isSelect: false },
  { id: 3, name: "求职招聘", path: "/", isSelect: false },
  { id: 4, name: "人才安居", path: "/", isSelect: false },
  { id: 5, name: "职称评定", path: "/", isSelect: false },
  { id: 6, name: "工程师协会", path: "/", isSelect: false },
  { id: 7, name: "人力资源产业园", path: "/", isSelect: false },
  { id: 8, name: "孵化·投资", path: "/", isSelect: false },
  { id: 9, name: "光谷驿站", path: "/", isSelect: false },
  { id: 10, name: "直播互动", path: "/", isSelect: true },
  { id: 11, name: "关于我们", path: "/", isSelect: false },
]);

// 切换tab
const handleTab = (id) => {
  window.open('https://www.ovc-talent.com/homePage', '@光谷人');
  // tabList.value.map((v) => {
  //   v.isSelect = false;
  //   if (id === v.id) {
  //     v.isSelect = true;
  //   }
  // });
};

const goCenter = () => {
  router.push({ path: "/baseInfo" });
};
const currentRoute = ref("");
watchEffect(() => {
  currentRoute.value = router.currentRoute.value.fullPath;
  if (Cookies.get("user")) {
    userInfo.value = JSON.parse(Cookies.get("user"));
  } else {
    userInfo.value = null;
  }
});
</script>
<template>
  <header>
    <img src="@/assets/index/header_bg.png" class="bg" alt="">
    <div>
      <div class="tab">
        <div class="content-box">
          <template v-for="(item, index) in tabList" :key="index">
            <div class="tab-item" @click="handleTab(item.id)">
              <div class="active" v-if="item.isSelect"></div>
              {{ item.name }}
            </div>
            <div class="tab-line" v-if="tabList.length != index + 1"></div>
          </template>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped lang="scss">
header {
  width: 100vw;
  height: 140px;
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // object-fit: contain;
  }

  // line-height: 140px;
  // font-size: 1.25rem;
  // background: url('@/assets/index/header_bg.png');
  // background-size: 100% 100%;
  // background-position: center;
  // position: sticky;
  // top: 0;
  // left: 50%;
  // // transform: translateX(-50%)
}

.login {
  display: flex;
  align-items: center;
}

.block_1 {
  background-color: rgba(255, 255, 255, 1);
  justify-content: space-between;
  display: flex;
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}
.tab {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  max-width: 1920px;
  height: 40px;
  background: rgba(44, 60, 111, 0.8);
  border-radius: 0px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  .content-box {
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .tab-item {
    font-weight: 400;
    font-size: 12px;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    position: relative;
  }
  .tab-line {
    width: 1px;
    height: 15px;
    background-color: #fff;
    margin: 0 15px;
  }
  .active {
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 2px;
    background-color: #fff;
    bottom: 8px;
    left: 0;
  }
}

.text_1 {
  font-size: 1.25rem;
  color: #333333;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: bold;
}
.noPointer {
  cursor: pointer;
}

.box_1 {
  width: 2.12rem;
  height: 2.12rem;
  background-color: #c30a05;
  border-radius: 50%;
  overflow: hidden img {
    width: 100%;
    height: 100%;
  }
}
.box_1,
.text_1 {
  display: inline-flex;
  vertical-align: middle;
  margin-right: 0.5rem;
}
.text_2,
.text_3 {
  font-size: 0.75rem;
  margin-left: 2.5rem;
  cursor: pointer;
}
.text_3 {
  color: #c30a05;
  display: inline-flex;
  align-items: center;

  img {
    width: 0.75rem;
    height: 0.875rem;
    margin-right: 0.25rem;
  }
}

.text_2 img {
  vertical-align: middle;
  width: 1.87rem;
  height: 1.87rem;
  margin-right: 0.62rem;
}
.text_4 {
  width: 5rem;
  height: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  color: #fff;
  background-color: #c30a05;
  border-radius: 2px;
  margin-left: 3.125rem;
  cursor: pointer;
  img {
    width: 0.875rem;
    height: 0.875rem;
    margin-right: 0.25rem;
  }
}
</style>
