import request from '../utils/request';

// 获取首页推荐banner列表
export function getIndexPlacementListApi(data) {
	return request({
		url: '/system/FrontEnd/placementList/' + data,
		method: "get"
	})
}

// 查询直播推荐列表
export function getLiveRecommendListApi(data) {
	return request({
		url: '/system/FrontEnd/list',
		method: "get",
		params: data
	})
}

// 获取全部直播列表
export function getLiveMoreListApi(data) {
	return request({
		url: '/system/FrontEnd/moreList',
		method: "get",
		params: data
	})
}

// 查询录播回放列表
export function getPlaybackListApi(data) {
	// let url = '';
	// if (data?.keyword) {
	// 	url = `system/FrontEnd/selectLivePlayback/${data?.keyword}`
	// } else {
	// 	url = 'system/FrontEnd/selectLivePlayback/list'
	// };
	return request({
		url: 'system/FrontEnd/selectLivePlayback/list',
		method: "get",
		params: data
	})
}

// 获取直播详情
export function getLiveDetailApi(data) {
	return request({
		url: `/system/FrontEnd/getLiveDetails/${data}`,
		method: "get"
	})
}

// 获取录播回放详情
export function getPlaybackDetailApi(data) {
	return request({
		url: 'system/FrontEnd/playback/' + data,
		method: "get"
	})
}

// 创建直播
export function createLiveApi(data) {
	return request({
		url: '/system/FrontEnd/createLive',
		method: "post",
		data
	})
}

// 修改直播
export function updateLiveApi(data) {
	return request({
		url: '/system/FrontEnd/updateLive',
		method: "post",
		data
	})
}

// 10.通过主播id查询直播信息
export function getLiveInfoByUserIdApi(id) {
	return request({
		url: `/system/FrontEnd/getUser/${id}`,
		method: "get"
	})
}

// 预约直播
export function reservationLiveApi(data) {
	return request({
		url: '/system/FrontEnd/ReservationLive',
		method: "post",
		data
	})
}

// 取消预约直播
export function translateLiveApi(data) {
	return request({
		url: '/system/FrontEnd/translate',
		method: "post",
		data
	})
}

// 获取直播预告详情
export function getLivePreviewApi(data) {
	return request({
		url: '/system/FrontEnd/Reservation',
		method: "post",
		data
	})
}

// 创建观看直播记录
export function watchHistoryApi(data) {
	return request({
		url: '/system/FrontEnd/watchHistory',
		method: "post",
		data
	})
}

// 获取观看直播记录列表
export function watchHistoryListApi(data) {
	return request({
		url: '/system/FrontEnd/watchHistory',
		method: "get",
		params: data
	})
}

// 图片上传
export function uploadApi(data) {
	return request({
		url: '/basic-api/resource/oss/upload',
		method: "post",
		data
	})
}