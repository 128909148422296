<script setup>
import { getVideoViewsListApi } from "../../api/video.js";
import { useRouter } from "vue-router";
const router = useRouter();
const loading = ref(true);

// 获取记录列表
const videoList = ref([]);
const total = ref(0);
const pageNum = ref(1);
const getVideoViewsList = () => {
  const params = {
    pageSize: 12,
    pageNum: pageNum.value,
  };
  getVideoViewsListApi(params).then((res) => {
    if (res.code === 200) {
      res.rows.map((v) => {
        if (!!v.videosVo) {
          v.videosVo.coverImageUrl = JSON.parse(v?.videosVo?.coverImageUrl)[0];
        }
      });
      videoList.value = res.rows;
      total.value = res.total;
    }
  });
};

// 跳转详情
const openDetail = (id) => {
  router.push("/videoCenter/videoDetail?id=" + id);
};

// 分页切换
const onchange = (e) => {
  pageNum.value = e;
  getVideoViewsList();
};

onMounted(() => {
  getVideoViewsList();
});
</script>

<template>
  <div class="page-browse">
    <template v-if="videoList && videoList.length > 0">
      <div class="list-content">
        <div
          class="list-item"
          v-for="(item, index) in videoList"
          :key="index"
          @click="openDetail(item.id)"
        >
          <div class="list-item-img">
            <img :src="item?.videosVo?.coverImageUrl?.url" />
            <div class="list-item-count flexRow">
              <div class="flexRow aICenter">
                <img src="@/assets/index/icon_eye.png" />
                <span>{{ item?.videosVo?.views || 0 }}</span>
              </div>
              <div class="flexRow aICenter">
                <img src="@/assets/icon/icon_like.png" />
                <span>{{ item?.videosVo?.likes || 0 }}</span>
              </div>
            </div>
          </div>
          <div class="list-item-info">
            <span class="desc text_line_2">{{ item?.videosVo?.title }}</span>
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          :background="false"
          layout="total, prev, pager, next"
          :page-size="12"
          :total="total"
          onchange="onchange"
        />
      </div>
    </template>
    <div v-else class="no_more">
      <el-empty description="暂无内容"></el-empty>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.el-pager .number) {
  background: #fff !important;
}
:deep(.el-pager .more) {
  background: #fff !important;
}
:deep(.el-pager .is-active) {
  background: #2c3c6f !important;
}
.page-browse {
  padding-bottom: 80px;
}
.list-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  .list-item {
    padding: 4px;
    box-shadow: 0px 4px 4px 0px rgba(18, 74, 163, 0.15);
    border: 1px solid #f6f6f6;
    background-color: #fff;
    cursor: pointer;
    .list-item-img {
      position: relative;
      img {
        width: 100%;
        height: 164px;
      }
      .list-item-count {
        position: absolute;
        bottom: 10px;
        left: 10px;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        line-height: 16px;
        > div {
          margin-right: 20px;
        }
        img {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
      }
    }
    .list-item-info {
      width: 100%;
      padding: 17px 10px;
      .desc {
        display: inline-block;
        line-height: 30px;
        font-weight: 600;
        font-size: 18px;
        color: #1f1f1f;
        word-break: break-all;
      }
      .liver {
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        line-height: 16px;
      }
    }
  }
}
.pagination {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
}

.no_more {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 100px auto 0;
}
</style>
