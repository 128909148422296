import request from '../utils/request';

// 获取视频列表
export function getVideoListApi(data) {
	return request({
		url: '/system/videos/list',
		method: "get",
		params: data
	})
}

// 通过综合排序查询列表
export function getVideoListByIntegratedApi(data) {
	return request({
		url: '/system/videos/weightsPueryList',
		method: "get",
		params: data
	})
}

// 通过播放量查询列表
export function getVideoListByPlayAmountApi(data) {
	return request({
		url: '/system/videos/viewsPueryList',
		method: "get",
		params: data
	})
}

// 通过点赞量查询列表
export function getVideoListByLikesAmountApi(data) {
	return request({
		url: '/system/videos/likesPueryList',
		method: "get",
		params: data
	})
}

// 获取分类列表
export function getCategotyListApi(data) {
	return request({
		url: '/system/categories/list',
		method: "get",
		params: data
	})
}

// 获取视频详情
export function getVideoDetailApi(data) {
	return request({
		url: '/system/videos/' + data,
		method: "get"
	})
}

// 新增浏览视频记录
export function tourVideolApi(data) {
	return request({
		url: '/system/videoViews/tourVideo',
		method: "post",
		data
	})
}

// 获取评论列表
export function getCommentListApi(data) {
	return request({
		url: '/system/comments/list',
		method: "get",
		params: data
	})
}

// 新增评论
export function addCommentsApi(data) {
	return request({
		url: '/system/comments',
		method: "post",
		data
	})
}

// 视频点赞
export function videoLikeApi(data) {
	return request({
		url: '/system/videoLikes',
		method: "post",
		data
	})
}

// 查询点赞列表
export function queryLikeListApi(data) {
	return request({
		url: '/system/videoLikes/list',
		method: "get",
		params: data
	})
}

// 评论点赞
export function commentLikeApi(data) {
	return request({
		url: '/system/commentLikes',
		method: "post",
		data
	})
}

// 获取视频浏览记录
export function getVideoViewsListApi(data) {
	return request({
		url: '/system/videoViews/list',
		method: "get",
		params: data
	})
}

// 获取用户评论视频记录列表
export function getUserCommentListApi(data) {
	return request({
		url: '/system/comments/all',
		method: "get",
		params: data
	})
}

// 获取系统消息列表
export function getAnnouncementListApi(data) {
	return request({
		url: '/system/announcement/all',
		method: "get",
		params: data
	})
}

// 获取被回复的消息列表
export function getCommentedListApi(data) {
	return request({
		url: '/system/comments/listlist',
		method: "get",
		params: data
	})
}