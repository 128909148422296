
import axios from 'axios';
import { ElMessage } from 'element-plus'
import { getToken, getClientKey } from "@/utils/auth";

const service = axios.create({
  baseURL: import.meta.env.VITE_TINTMCE_BASE_URL,
  timeout: 50000,
  withCredentials: true,
  headers: { 
    'Content-Type': 'application/json'
  }
});

// token暂时写死
const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJsb2dpblR5cGUiOiJsb2dpbiIsImxvZ2luSWQiOiJzeXNfdXNlcjoxODE4OTQxNTczNTMyOTM4MjQyIiwicm5TdHIiOiJlT1Bha3dKQWpIZUkzOU5hYnJLNkx6ZnBUc2tOMlZqZSIsImNsaWVudGlkIjoiZTVjZDdlNDg5MWJmOTVkMWQxOTIwNmNlMjRhN2IzMmUiLCJ0ZW5hbnRJZCI6IjAwMDAwMCIsInVzZXJJZCI6MTgxODk0MTU3MzUzMjkzODI0MiwiZGVwdElkIjoxMDV9.fTQXCIC890e-WMARDZSvnCzZupmR6Hnbdlsz2P_0GjE';
// const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJsb2dpblR5cGUiOiJsb2dpbiIsImxvZ2luSWQiOiJzeXNfdXNlcjoxIiwicm5TdHIiOiJoMXVhREUwNjBGS2FTVlR5UzZqUXBMOTJwZzdIY0NpbiIsImNsaWVudGlkIjoiZTVjZDdlNDg5MWJmOTVkMWQxOTIwNmNlMjRhN2IzMmUiLCJ0ZW5hbnRJZCI6IjAwMDAwMCIsInVzZXJJZCI6MSwiZGVwdElkIjoxMDN9.kC3nW-Lzpg6AdazmaB6SmmGUwd_QOSS-8jG5bH8V5Es'
const clientid = 'e5cd7e4891bf95d1d19206ce24a7b32e';

// 请求拦截
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  // 是否需要防止数据重复提交
  // const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
  if (getToken() && !isToken) {
    // config.headers['Authorization'] = 'Bearer ' + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    // config.headers['clientid'] = getClientKey();
  }
  config.headers['Authorization'] = 'Bearer ' + token;
  config.headers['clientid'] = clientid;
  return config;
}, error => {
  Promise.reject(error);
});

// 响应拦截
service.interceptors.response.use(response => {
  //判断code码
  return response.data;
}, error => {
  if (error && error.response) {
    switch (error.response.status) {
      case 403:
        error.message = '拒绝访问'
        break;
      case 502:
        error.message = '服务器访问错误'
        break;
      default:
        error.message = `连接错误${error.message}`

    }
  } else {
    error.message = '响应超时，请刷新当前页面'
  }

  ElMessage.error({ message: error.message })
  return Promise.reject(error);
});

export default service;