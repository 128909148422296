<script setup>
import { getCommentedListApi } from "../../api/video.js";
import { useRouter } from "vue-router";
const router = useRouter();

const loading = ref(true);
const pageNum = ref(1);
const commentList = ref([]);
const total = ref(0);

// 获取评论列表
const getCommentList = () => {
  const params = {
    createBy: localStorage.getItem("userId"),
    pageSize: 10,
    pageNum: pageNum.value,
  };
  getCommentedListApi(params).then((res) => {
    if (res.code === 200) {
      let data = res.rows;
      data.map((v) => {
        v.coverImageUrl = JSON.parse(v.coverImageUrl)[0];
        v.avatar = JSON.parse(v.avatar)[0];
      });
      commentList.value = data;
      total.value = res.total;
      loading.value = false;
    }
  });
};

// 分页
const onChange = (e) => {
  pageNum.value = e;
  getCommentList();
  document.documentElement.scrollTop = 0;
  document.body.scrollTop = 0;
};

// 跳转详情
const openDetail = (id) => {
  router.push("/videoCenter/videoDetail?id=" + id);
};

onMounted(() => {
  getCommentList();
});
</script>

<template>
  <div class="page-myComment">
    <template v-if="commentList && commentList.length > 0">
      <div class="comment-list">
        <div
          class="list-item"
          v-for="(item, index) in commentList"
          :key="index"
        >
          <div class="item-left">
            <div class="left-info">
              <img class="info-avatar" :src="item.avatar?.url" />
              <span class="info-name">{{ item.cuserIdStr }}</span>
              <span class="info-comment">{{ item.content }}</span>
              <div class="flexRow aICenter" style="margin-top: 7px">
                <div class="info-time">{{ item.createTime }}</div>
                <div class="info-reply">
                  <span v-if="!item.userId">
                    评论了：
                    <span
                      class="info-target"
                      @click="openDetail(item.videosId)"
                    >
                      {{ item.videosIdStr }}
                    </span>
                  </span>
                  <span v-else>
                    回复了：
                    <span class="info-target">@{{ item.userIdStr }}</span>
                    <span style="color: #1890ff">的发帖</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <img
            class="item-img"
            :src="item.coverImageUrl?.url"
            @click="openDetail(item.videosId)"
          />
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          :background="true"
          layout="total, prev, pager, next"
          :total="total"
          :page-size="10"
          @change="onChange"
        />
      </div>
    </template>
    <div v-else class="no_more">
      <el-empty description="暂无内容"></el-empty>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.el-pager .number) {
  background: #fff !important;
}
:deep(.el-pager .more) {
  background: #fff !important;
}
:deep(.el-pager .is-active) {
  background: #2c3c6f !important;
}
.page-myComment {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(18, 74, 163, 0.15);
  border: 1px solid #f6f6f6;
  padding: 0 20px;
  margin-bottom: 70px;
  .comment-list {
    .list-item {
      padding: 30px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e7e7e7;
      .item-left {
        flex: 1;
        margin-right: 30px;
        .info-avatar {
          width: 24px;
          height: 24px;
          flex-shrink: 0;
          display: inline;
          position: relative;
          top: 6px;
        }
        .info-name {
          font-weight: 600;
          font-size: 14px;
          color: #2c3c6f;
          line-height: 16px;
          margin: 0 20px 0 10px;
          flex-shrink: 0;
          display: inline;
          line-height: 24px;
        }
        .info-comment {
          display: inline;
          word-break: break-all;
          font-weight: 400;
          font-size: 15px;
          color: #1f1f1f;
          line-height: 24px;
        }
        .info-time {
          font-weight: 400;
          font-size: 12px;
          color: #999999;
          line-height: 14px;
          margin-right: 30px;
        }
        .info-reply {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #999999;
          .info-target {
            color: #1890ff;
            text-decoration: underline;
            cursor: pointer;
            margin-right: 4px;
          }
        }
      }
      .item-img {
        width: 116px;
        height: 69px;
        flex-shrink: 0;
        cursor: pointer;
      }
    }
  }
}
.pagination {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
}

.no_more {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 112px auto;
}
</style>
