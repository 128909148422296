import Cookies from 'js-cookie'

const TokenKey = 'Authorization'

const clientKey = 'clientid'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken(TokenKey) {
  return Cookies.remove(TokenKey)
}

export function getClientKey() {
  return Cookies.get(clientKey)
}
