<script setup>
import { onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();
const activeIndex = ref(1);
import navImg_1 from '@/assets/index/icon_live.png';
import navImg_2 from '@/assets/index/icon_video.png';
import navImg_3 from '@/assets/index/icon_my.png';
const navList = ref([
  {
    id: 1,
    img: navImg_1,
    label: "直播",
    path: "/liveCenter",
    name: "LiveMain",
  },
  {
    id: 2,
    img: navImg_2,
    label: "视频",
    path: "/videoCenter",
    name: "VideoCenter",
  },
  {
    id: 3,
    img: navImg_3,
    label: "我的",
    path: "/personalCenter",
    name: "PersonalCenter",
  },
]);
const navJump = (item) => {
  if (item.id === activeIndex.value) return;
  router.push(item.path);
  activeIndex.value = item.id;
};

watch(
  () => route.fullPath,
  (val) => {
    navList.value.map((v) => {
      if (val.includes(v.path)) {
        activeIndex.value = v.id;
      }
    });
  }
);

onMounted(() => {
  const path = route.fullPath;
  navList.value.map((v) => {
    if (path.includes(v.path)) {
      activeIndex.value = v.id;
    }
  });
});
</script>

<template>
  <div class="common-layout">
    <div class="bg_container_1">
      <el-carousel :interval="5000" arrow="hover" style="width: 100vw; max-width: 1920px;" height="280" autoplay>
        <el-carousel-item>
          <img src="@/assets/index/index_bg_2.png" class="bg_2" />
          <div class="bg_content">
            <div class="txt1">直播互动</div>
            <div class="txt2">Live Interactive</div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <img src="https://www.ovc-talent.com/rczc/2024/09/27/0008.png" class="bg_2" />
          <div class="bg_content">
            <!-- <div class="txt1">庆祝国庆</div>
            <div class="txt2">Celebrate National Day</div> -->
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="nav">
        <div
          class="item"
          :class="item.id === activeIndex ? 'isCurrent' : ''"
          v-for="(item, index) in navList"
          :key="index"
          @click="navJump(item)"
        >
          <div class="active" v-if="item.id === activeIndex"></div>
          <div class="item_content">
            <img :src="item.img" />
            {{ item.label }}
          </div>
        </div>
      </div>
    </div>
    <router-view v-slot="{ Component, route }">
      <keep-alive v-if="route.meta">
        <component :is="Component"></component>
      </keep-alive>
    </router-view>
  </div>
</template>
<style lang="scss" scoped>
.common-layout {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .bg_container_1 {
    position: relative;
    height: 280px;
    max-width: 1920px;
    margin: 0 auto;
    .bg_2 {
      width: 100%;
      height: 280px;
    }
    .bg_content {
      width: 1200px;
      height: auto;
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      .txt1 {
        font-weight: 600;
        font-size: 40px;
        color: #2c3c6f;
        line-height: 47px;
      }
      .txt2 {
        font-weight: 600;
        font-size: 24px;
        color: rgba(44, 60, 111, 0.6);
        line-height: 28px;
      }
    }
    .nav {
      width: 1200px;
      height: 110px;
      margin: 0 auto;
      background: #ffffff;
      box-shadow: 0px 4px 4px 0px rgba(18, 74, 163, 0.15);
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      top: -60px;
      z-index: 99;
      .item {
        flex: 1;
        font-weight: 600;
        font-size: 24px;
        color: #2C3C6F;
        line-height: 28px;
        border-right: 1px solid #d7d7d7;
        position: relative;
        cursor: pointer;
        .item_content {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          z-index: 1;
          font-size: 24px;
          font-weight: 600;
          img {
            width: 60px;
            height: 60px;
            margin-right: 40px;
          }
        }
        .active {
          width: 268px;
          height: 60px;
          background: linear-gradient(180deg, #cfddff 0%, #5d8dfe 100%);
          opacity: 0.3;
          border-radius: 8px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
        }
      }
      .isCurrent {
        color: #306FF0;
      }
      .item:last-child {
        border: none;
      }
    }
  }
  .bg_container_2 {
    position: relative;
    height: 490px;
    position: relative;
    .bg_3 {
      width: 100%;
      height: 490px;
      position: absolute;
      top: 0;
      left: 0;
    }
    .main_box {
      width: 1200px;
      margin: 0 auto;
      position: relative;
      z-index: 1;
      .nav {
        width: 100%;
        height: 110px;
        background: #ffffff;
        box-shadow: 0px 4px 4px 0px rgba(18, 74, 163, 0.15);
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .item {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          font-size: 24px;
          color: #306ff0;
          line-height: 28px;
          border-right: 1px solid #d7d7d7;
          cursor: pointer;
          img {
            width: 60px;
            height: 60px;
            margin-right: 40px;
          }
        }
        .item:last-child {
          border: none;
        }
      }
      .live_box {
        margin-top: 90px;
        width: 100%;
        height: 360px;
        background-color: #eee;
        display: flex;
        .live_box_left {
          width: 970px;
          height: 100%;
          position: relative;
          .live_box_left_banner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #fff;
          }
          .live_box_left_status {
            position: absolute;
            top: 12px;
            left: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 400;
            font-size: 14px;
            color: #ff3950;
            background: #ffefef;
            width: 90px;
            height: 30px;
            border-radius: 15px;
            img {
              width: 24px;
              height: 24px;
              margin-right: 8px;
            }
          }
          .live_box_left_enter {
            cursor: pointer;
            position: absolute;
            bottom: 20px;
            right: 27px;
            width: 164px;
            height: 54px;
            background: rgba(31, 31, 31, 0.39);
            border-radius: 2px;
            border: 1px solid rgba(255, 255, 255, 0.5);
            font-weight: 400;
            font-size: 16px;
            color: rgba(255, 255, 255, 0.8);
            text-align: center;
            line-height: 54px;
            border: 1px solid rgba(255, 255, 255, 0.5);
          }
        }
        .live_box_right {
          flex: 1;
          .live_box_right_item {
            box-sizing: border-box;
            width: 100%;
            height: 72px;
            text-align: center;
            line-height: 72px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);
            font-weight: 400;
            font-size: 16px;
            color: rgba(255, 255, 255, 0.8);
            background: rgba(44, 60, 111, 0.8);
            cursor: pointer;
          }
          .live_box_right_item:last-child {
            border: none;
          }
        }
      }
    }
  }
  .live_box_main {
    width: 1200px;
    margin: 0 auto;
    padding: 70px 0;
    display: flex;
    justify-content: space-between;
    .live_box_section {
      width: 590px;
      .live_box_section_title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .label {
          font-weight: 600;
          font-size: 24px;
          color: #1f1f1f;
          line-height: 28px;
          margin-left: 10px;
        }
        .eng {
          font-weight: 400;
          font-size: 16px;
          color: #999999;
          line-height: 19px;
          margin-left: 10px;
        }
      }
      .live_box_section_content {
        width: 590px;
        box-sizing: border-box;
        background: linear-gradient(180deg, #eff3fa 0%, #fdfeff 100%);
        box-shadow: 0px 4px 4px 0px rgba(18, 74, 163, 0.15);
        border: 4px solid #ffffff;
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* 五列网格，每列平均分布 */
        gap: 28px; /* 列之间的间距 */
        padding: 24px 24px 20px;
        justify-content: space-between;
        .live_video_item {
          width: 257px;
          height: 257px;
          cursor: pointer;
          background-color: #fff;
          .live_video_item_img {
            width: 100%;
            height: 164px;
            position: relative;
            img {
              width: 100%;
              height: 100%;
            }
            .views {
              position: absolute;
              bottom: 8px;
              left: 8px;
              font-weight: 400;
              font-size: 14px;
              color: #ffffff;
              line-height: 16px;
              display: flex;
              align-items: center;
              img {
                width: 16px;
                height: 16px;
                margin-right: 4px;
              }
            }
          }
          .live_video_item_content {
            width: 100%;
            padding: 20px 10px 0;
            box-sizing: border-box;
            .live_video_item_content_title {
              width: 100%;
              font-weight: 600;
              font-size: 18px;
              color: #1f1f1f;
              line-height: 21px;
            }
            .live_video_item_content_name {
              font-weight: 400;
              font-size: 14px;
              color: #999999;
              line-height: 16px;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  .video_box_main {
    width: 100%;
    max-width: 1920px;
    height: 1100px;
    margin: 0 auto;
    background-image: url("@/assets/index/index_bg_4.png");
    background-size: 100% 100%;
    background-position: center;
    padding-top: 70px;
    .video_box_main_container {
      width: 1200px;
      margin: 0 auto;
    }
    .video_box_main_title_txt {
      font-weight: 600;
      font-size: 24px;
      color: #1f1f1f;
      line-height: 28px;
      margin: 0 20px 10px;
    }
    .video_box_main_title_eng {
      font-weight: 400;
      font-size: 16px;
      color: #999999;
      line-height: 19px;
      margin-bottom: 20px;
    }
    .more {
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      line-height: 16px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 20px;
      cursor: pointer;
      img {
        width: 6px;
        height: 9px;
        margin-left: 4px;
      }
    }
    .video_box_main_list {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr); /* 五列网格，每列平均分布 */
      gap: 24px 20px; /* 列之间的间距 */
      box-sizing: border-box;
      .video_box_main_list_item {
        width: 285px;
        background: #ffffff;
        box-shadow: 0px 4px 4px 0px rgba(18, 74, 163, 0.15);
        border: 1px solid #f6f6f6;
        padding: 4px;
        box-sizing: border-box;
        cursor: pointer;
        img {
          width: 100%;
          height: 164px;
        }
        .video_box_main_list_item_content {
          box-sizing: border-box;
          margin-top: 20px;
          padding: 0 10px 20px;
          .title {
            font-weight: 600;
            font-size: 18px;
            color: #1f1f1f;
            line-height: 21px;
          }
          .liver {
            font-weight: 400;
            font-size: 14px;
            color: #999999;
            line-height: 16px;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
</style>
