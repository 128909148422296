<script setup>
import { watchHistoryListApi } from "../../api/live";
import { useRouter } from "vue-router";
const router = useRouter();

const watchHistoryList = ref([]);
const pageNum = ref(1);
const total = ref(0);

// 获取浏览记录
const getWatchHistory = () => {
  const params = {
    pageNum: 1,
    pageSize: 12,
    userId: localStorage.getItem("customId"),
  };
  watchHistoryListApi(params).then((res) => {
    if (res.code === 200) {
      let data = res.data.rows;
      data.map((v) => {
        v.livePictureAddress = JSON.parse(v.livePictureAddress)[0];
      });
      watchHistoryList.value = data;
      total.value = res.data.total;
    }
  });
};

// 跳转详情
const openDetail = (item) => {
  if (item.liveState === "0") {
    router.push("/liveBroadcastPreview?id=" + item.liveId);
  } else if (item.liveState === "1") {
    router.push("/liveBroadcastDetail?id=" + item.liveId);
  } else if (item.liveState === "2") {
    router.push("/livePause?id=" + item.liveId);
  }
};

// 分页
const onChange = (e) => {
  pageNum.value = e;
  getWatchHistory();
};

onMounted(() => {
  getWatchHistory();
});
</script>

<template>
  <div class="page-browse">
    <template v-if="watchHistoryList && watchHistoryList.length > 0">
      <div class="list-content">
        <div
          class="list-item"
          v-for="(item, index) in watchHistoryList"
          :key="index"
          @click="openDetail(item)"
        >
          <div class="list-item-img">
            <img :src="item.livePictureAddress?.url" alt="" />
            <div
              class="list-item-islive flexRow aICenter jCCenter"
              v-if="item.liveState === '1'"
            >
              <img src="@/assets/gif/live.gif" />
              <span>直播中</span>
            </div>
            <div class="list-item-count flexRow">
              <div class="flexRow aICenter">
                <img src="@/assets/index/icon_eye.png" />
                <span>{{ item.views || 0 }}</span>
              </div>
              <!-- <div class="flexRow aICenter">
                <img src="@/assets/icon/icon_like.png" />
                <span>{{ item.likes || 0 }}</span>
              </div> -->
            </div>
          </div>
          <div class="list-item-info">
            <span class="desc">{{ item.title }}</span>
            <div class="liver">主播：{{ item.anchorName }}</div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          :background="true"
          layout="total, prev, pager, next"
          :total="total"
          :page-size="12"
          @change="onChange"
        />
      </div>
    </template>
    <div v-else class="no_more">
      <el-empty description="暂无内容"></el-empty>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.el-pager .number) {
  background: #fff !important;
}
:deep(.el-pager .more) {
  background: #fff !important;
}
:deep(.el-pager .is-active) {
  background: #2c3c6f !important;
}
.page-browse {
  padding-bottom: 40px;
}
.list-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  .list-item {
    padding: 4px;
    box-shadow: 0px 4px 4px 0px rgba(18, 74, 163, 0.15);
    border: 1px solid #f6f6f6;
    background-color: #fff;
    cursor: pointer;
    .list-item-img {
      position: relative;
      .list-item-islive {
        width: 89px;
        height: 30px;
        background: #ffefef;
        border-radius: 15px;
        position: absolute;
        top: 7px;
        left: 4px;
        font-weight: 400;
        font-size: 14px;
        color: #ff3950;
        img {
          width: 24px;
          height: 24px;
          margin-right: 4px;
        }
      }
      img {
        width: 100%;
        height: 164px;
      }
      .list-item-count {
        position: absolute;
        bottom: 10px;
        left: 10px;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        line-height: 16px;
        > div {
          margin-right: 20px;
        }
        img {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
      }
    }
    .list-item-info {
      width: 100%;
      padding: 17px 10px;
      .desc {
        display: inline-block;
        line-height: 30px;
        font-weight: 600;
        font-size: 18px;
        color: #1f1f1f;
        word-break: break-all;
        width: 285px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 10px;
      }
      .liver {
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        line-height: 16px;
      }
    }
  }
}
.pagination {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
  background-color: #fff;
  padding-right: 30px;
}

.no_more {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 100px auto 0;
}
</style>
