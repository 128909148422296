<script setup>
import {
  getUserCommentListApi,
  getAnnouncementListApi,
  getCommentedListApi,
} from "../../api/video.js";
import { useRouter } from "vue-router";
const router = useRouter();

const nav = ref([
  { name: "全部", id: 1 },
  { name: "回复我的", id: 2 },
  { name: "系统消息", id: 3 },
]);
const navId = ref(1);
const pageNum = ref(1);
const allMessageList = ref([]);
const total = ref(0);
const loading = ref(true);

// 获取全部列表数据
const getMessageList = () => {
  const params = {
    userId: localStorage.getItem("userId"),
    pageSize: 10,
    pageNum: pageNum.value,
  };
  getUserCommentListApi(params).then((res) => {
    let data = res.rows;
    data.map((v) => {
      if (v.avatar) {
        v.avatar = JSON.parse(v.avatar)[0];
      };
      if (v.coverImageUrl) {
        v.coverImageUrl = JSON.parse(v.coverImageUrl)[0];
      }
    });
    console.log(data);
    
    allMessageList.value = data;
    total.value = res.total;
    loading.value = false;
  });
};

// 获取系统消息列表
const getAnnouncementList = () => {
  const params = {
    pageNum: pageNum.value,
    pageSize: 10,
  };
  getAnnouncementListApi(params).then((res) => {
    allMessageList.value = res.rows;
    total.value = res.total;
    loading.value = false;
  });
};

// 获取回复消息列表
const getCommentList = () => {
  const params = {
    userId: localStorage.getItem("userId"),
    pageSize: 10,
    pageNum: pageNum.value,
  };
  getCommentedListApi(params).then((res) => {
    let data = res.rows;
    data.map((v) => {
      if (v.avatar) {
        v.avatar = JSON.parse(v.avatar)[0];
      };
      if (v.coverImageUrl) {
        v.coverImageUrl = JSON.parse(v.coverImageUrl)[0];
      };
    });
    allMessageList.value = data;
    total.value = res.total;
    loading.value = false;
  });
};

// 分类切换
const handleChange = (id) => {
  navId.value = id;
  pageNum.value = 1;
  loading.value = true;
  if (id === 1) {
    getMessageList();
  } else if (id === 2) {
    getCommentList();
  } else {
    getAnnouncementList();
  }
};

// 跳转网页链接
const handleLink = (url) => {
  window.open(url, "_blank");
};

// 跳转视频详情
const handleVideoDetail = (id) => {
  router.push("/videoCenter/videoDetail?id=" + id);
};

// 分页
const onChange = (e) => {
  pageNum.value = e;
  loading.value = true;
  document.documentElement.scrollTop = 0;
  document.body.scrollTop = 0;
  if (id === 1) {
    getMessageList();
  } else if (id === 2) {
    getCommentList();
  } else {
    getAnnouncementList();
  }
};

onMounted(() => {
  getMessageList();
});
</script>

<template>
  <div class="page-message">
    <div class="message-header">
      <div
        class="tab-item"
        :class="navId === item.id ? 'active' : ''"
        v-for="(item, index) in nav"
        :key="index"
        @click="handleChange(item.id)"
      >
        {{ item.name }}
      </div>
    </div>
    <template v-if="allMessageList && allMessageList.length > 0">
      <div class="message-list">
        <template v-for="(item, index) in allMessageList" :key="index">
          <div class="list-item" v-if="item.linkUrl">
            <!-- 网页链接 -->
            <div class="item-top flexRow">
              <!-- <div class="dot"></div> -->
              <div>
                <div class="flexRow aICenter">
                  <div class="link-title">{{ item.title }}</div>
                  <div class="time">{{ item.createTime }}</div>
                </div>
                <div class="item-bottom">
                  <span>{{ item.content }} >></span>
                  <span
                    class="link-btn"
                    style="margin-left: 4px"
                    @click="handleLink(item.linkUrl)"
                    >网页链接</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="list-item" v-if="item.coverImageUrl">
            <!-- 评论消息 -->
            <div class="item-comment flexRow aICenter">
              <div class="item-left">
                <div class="item-top flexRow aICenter">
                  <img :src="item?.avatar?.url" class="item-avatar" />
                  <span class="item-name">{{ item.cuserIdStr }}</span>
                  <span>回复您在</span>
                  <span
                    class="link-btn"
                    @click="handleVideoDetail(item.videosId)"
                    >{{ item.videosIdStr }}</span
                  >
                  <span>的帖子</span>
                  <span class="time" style="margin-left: 20px">{{
                    item.createTime
                  }}</span>
                </div>
                <div class="item-bottom">
                  <span>{{ item.content }} >></span>
                  <span
                    class="link-btn"
                    style="margin-left: 4px"
                    @click="handleVideoDetail(item.videosId)"
                    >查看完整评论</span
                  >
                </div>
              </div>
              <img
                class="item-img pointer"
                :src="item.coverImageUrl?.url"
                @click="handleVideoDetail(item.videosId)"
              />
            </div>
          </div>
        </template>
      </div>
      <div class="pagination">
        <el-pagination
          :background="true"
          layout="total, prev, pager, next"
          :total="total"
          :page-size="10"
          @change="onChange"
        />
      </div>
    </template>
    <div v-else class="no_more">
      <el-empty description="暂无内容"></el-empty>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.el-pager .number) {
  background: #fff !important;
}
:deep(.el-pager .more) {
  background: #fff !important;
}
:deep(.el-pager .is-active) {
  background: #2c3c6f !important;
}
.page-message {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(18, 74, 163, 0.15);
  border: 1px solid #f6f6f6;
  padding: 0 20px;
  margin-bottom: 50px;
  .message-header {
    height: 70px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e7e7e7;
    .tab-item {
      padding: 7px 10px;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 16px;
      margin-right: 20px;
      cursor: pointer;
    }
    .active {
      background: #2c3c6f;
      color: #fff;
      border-radius: 1px;
    }
  }
  .list-item {
    padding: 30px 0;
    border-bottom: 1px solid #e7e7e7;
    .item-top {
      margin-bottom: 4px;
      font-weight: 400;
      font-size: 15px;
      color: #1f1f1f;
      line-height: 24px;
    }
    .dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #1890ff;
      margin-right: 10px;
      position: relative;
      top: 6px;
    }
    .link-title {
      font-weight: 600;
      font-size: 15px;
      color: #2c3c6f;
      line-height: 18px;
      margin-right: 20px;
    }
    .time {
      font-weight: 400;
      font-size: 12px;
      color: #999999;
      line-height: 14px;
    }
    .item-bottom {
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 24px;
      padding-left: 4px;
    }
    .link-btn {
      color: #1890ff;
      text-decoration-line: underline;
      cursor: pointer;
    }
    .item-left {
      flex: 1;
    }
    .item-img {
      width: 116px;
      height: 69px;
      margin-left: 30px;
    }
    .item-avatar {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    .item-name {
      font-weight: 600;
      font-size: 14px;
      color: #2c3c6f;
      line-height: 16px;
      margin-right: 10px;
    }
  }
}
.pagination {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
}

.no_more {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 77px auto;
}
</style>
