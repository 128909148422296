<script setup>
import Breadcrumb from "../../components/breadcrumb/index.vue";
import { useRouter } from "vue-router";
import { onMounted } from "vue";
import { getLiveMoreListApi } from "../../api/live.js";
const router = useRouter();

const loading = ref(true);
const streamingList = ref([]);
const previewList = ref([]);
const currentLiveData = ref({});

// 获取直播列表
const getLiveRecommendList = (type) => {
  getLiveMoreListApi().then((res) => {
    streamingList.value = res.data.livingList;
    previewList.value = res.data.heraldList;
    loading.value = false;
    if (streamingList.value && streamingList.value.length > 0) {
      streamingList.value.map(v => {
        v.livePictureAddress = JSON.parse(v.livePictureAddress)[0];
      });
    }
    if (previewList.value && previewList.value.length > 0) {
      previewList.value.map(v => {
        v.livePictureAddress = JSON.parse(v.livePictureAddress)[0];
      });
    }
    if (type === "first") {
      currentLiveData.value = streamingList.value[0];
    }
  });
};

// 切换当前直播
const handleChangeCurrentData = (item) => {
  currentLiveData.value = item;
}

// 跳转详情
const openDetail = () => {
  router.push("/liveBroadcastDetail?id=" + currentLiveData.value.id);
};

// 跳转预告
const openPreview = (item) => {
  router.push("/liveBroadcastPreview?id=" + item.id);
};

onMounted(() => {
  getLiveRecommendList("first");
});
</script>

<template>
  <div class="page-container" v-loading="loading">
    <Breadcrumb name="直播列表"></Breadcrumb>
    <div class="page-content">
      <div class="page-title flexRow aICenter">
        <div class="cube"></div>
        <div class="title">
          <span class="title-txt">正在直播</span>
          <span class="title-eng">Live Streaming</span>
        </div>
      </div>
      <div class="page-main">
        <div class="main-left">
          <div class="enter-btn" @click="openDetail(currentLiveData.id)" v-if="currentLiveData">进入直播间</div>
          <img :src="currentLiveData?.livePictureAddress?.url" />
        </div>
        <div class="main-right">
          <div
            class="right-item"
            :class="item.id === currentLiveData.id ? 'right-active' : ''"
            v-for="(item, index) in streamingList"
            :key="index"
            @click="handleChangeCurrentData(item)"
          >
            <img :src="item?.livePictureAddress?.url" />
            <div class="right-item_status" v-if="item.liveState === '1'">
              <img src="@/assets/gif/live.gif" />
              直播中
            </div>
            <div class="right-item_views">
              <img src="@/assets/index/icon_eye.png">
              {{ item.views || 0 }}
            </div>
          </div>
        </div>
      </div>
      <div class="page-title flexRow aICenter">
        <div class="cube"></div>
        <div class="title">
          <span class="title-txt">直播预告</span>
          <span class="title-eng">Live Streaming Preview</span>
        </div>
      </div>
      <div class="live-preview">
        <div
          class="preview-item"
          v-for="(item, index) in previewList"
          :key="index"
          @click="openPreview(item)"
        >
          <img :src="item?.livePictureAddress?.url" />
          <div class="preview-item-content">
            <div class="name text_line_1">{{ item.title }}</div>
            <div class="time">时间：{{ item.liveTimeStart }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page-container {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 70px;
  max-width: 1920px;
  background: #f5f5f7;
  .breadcrumb {
    width: 100%;
    margin: 0 auto;
    height: 37px;
    max-width: 1920px;
    background: #fff;
    display: flex;
    align-items: center;
    .breadcrumb_content {
      width: 1200px;
      margin: 0 auto;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      line-height: 14px;
      span {
        cursor: pointer;
      }
      img {
        width: 16px;
        height: 16px;
      }
      .arrow {
        width: 6px;
        height: 9px;
        margin: 0 10px;
      }
    }
  }
  .page-content {
    width: 1200px;
    margin: 0 auto;
    padding-top: 20px;
    .page-title {
      margin-bottom: 20px;
      .title {
        margin-left: 10px;
      }
    }
    .page-main {
      width: 100%;
      height: 500px;
      display: flex;
      margin-bottom: 60px;
      .main-left {
        width: 895px;
        height: 100%;
        background-color: #eee;
        position: relative;
        .enter-btn {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 164px;
          height: 54px;
          background: rgba(31, 31, 31, 0.75);
          border-radius: 2px 2px 2px 2px;
          border: 1px solid rgba(255, 255, 255, 0.5);
          font-weight: 400;
          font-size: 16px;
          color: rgba(255, 255, 255, 0.8);
          line-height: 54px;
          text-align: center;
          cursor: pointer;
          transform: translateX(-50%) translateY(-50%);
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .main-right {
        flex: 1;
        height: 500px;
        background: rgba(44, 60, 111, 0.8);
        padding: 20px 14px;
        box-sizing: border-box;
        overflow: hidden;
        overflow-y: auto;
        .right-item {
          width: 277px;
          height: 164px;
          opacity: 0.43;
          margin-bottom: 20px;
          background-color: #fff;
          cursor: pointer;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
          .right-item_status {
            position: absolute;
            top: 7px;
            left: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 400;
            font-size: 14px;
            color: #ff3950;
            background: #ffefef;
            width: 90px;
            height: 30px;
            border-radius: 15px;
            img {
              width: 24px;
              height: 24px;
              margin-right: 8px;
            }
          }
          .right-item_views {
            position: absolute;
            bottom: 11px;
            left: 20px;
            display: flex;
            align-items: center;
            color: #fff;
            font-size: 14px;
            img {
              width: 16px;
              height: 16px;
              margin-right: 3px;
            }
          }
        }
        .right-active {
          opacity: 1;
          border: 2px solid #1890FF;
        }
      }
      ::-webkit-scrollbar {
        width: 0;
        display: none;
      }
    }
    .live-preview {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 28px;
      .preview-item {
        box-sizing: border-box;
        width: 285px;
        padding: 4px 4px 0;
        background: #ffffff;
        box-shadow: 0px 4px 4px 0px rgba(18, 74, 163, 0.15);
        border: 1px solid #f6f6f6;
        cursor: pointer;
        img {
          width: 100%;
          height: 164px;
          object-fit: cover;
          object-position: center;
        }
        .preview-item-content {
          margin-top: 20px;
          padding: 0 10px 20px;
          .name {
            font-weight: 600;
            font-size: 18px;
            color: #1f1f1f;
            line-height: 21px;
          }
          .time {
            font-weight: 400;
            font-size: 14px;
            color: #999999;
            line-height: 16px;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
</style>
